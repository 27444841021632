<template>
  <ReportDataTable :urls="urls" />
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ReportDataTable from "@/own/components/reports/ReportDataTable.vue";
export default {
  name: "DeliveryEstimatedAccuracy",
  components: { ReportDataTable },
  data: () => ({
    urls: {
      data_url: "/reports/deliveryEstimatedAccuracy",
      show_url: "/reports/deliveryEstimatedAccuracy/show",
    },
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
